import * as Sentry from '@sentry/vue';
import { sessionStorageService } from '@@/utils/StorageService';
import { useCookieWrapper } from '@@/composables/useCookieWrapper';
import { useUserStore } from '@@/stores/User';
import { useInAppView } from '@@/composables/useInAppView';
import { useNuxtApp } from 'nuxt/app';

// ----------------------------------------------------------------------------------------------
// Braze Tracking
// ----------------------------------------------------------------------------------------------

const canUseBraze = () => {
  const config = useRuntimeConfig().public;
  const route = useRoute();
  const userStore = useUserStore();

  if (!config.brazeSdkApiKey || !config.brazeSdkEndpoint) {
    console.log('Braze: Not loaded, missing API key or SDK endpoint');
    return false;
  }

  if (userStore.isGuest && !config.brazeTrackGuests) {
    console.log('Braze: Not tracking guest users');
    return false;
  }

  // No braze sessions on the homepage for guests because that's a lot of sessions
  if (userStore.isGuest && useRoute().path === '/') {
    console.log('Braze: Not tracking guest users on home page');
    return false;
  }

  // No braze sessions on certain pages (login, reg, buy) to prevent marketing popups
  if (config.brazeDisallowPaths.includes(route.path)) {
    console.log('Braze: Not tracking users on disallowed paths');
    return false;
  }

  return true;
};

const isBrazeLoaded = () => {
  const { $braze } = useNuxtApp();
  return !!$braze;
};

const initializeBraze = async () => {
  const config = useRuntimeConfig().public;

  if (!canUseBraze()) {
    return;
  }

  // The braze/web-sdk cannot be imported on the server side since it uses the navigator
  // object which isn't available on the server. So it is dynamically imported here when
  // initializeBraze() is called.
  const braze = await import('@braze/web-sdk');

  braze.enableSDK();

  // Initialize Braze using the configuration values from the .env file. Note that by default
  // logging is enabled in development. In staging and production, it can be enabled from the
  // developer console by running: $nuxt.$braze.toggleLogging()
  const success = braze.initialize(config.brazeSdkApiKey, {
    allowUserSuppliedJavascript: true,
    baseUrl: config.brazeSdkEndpoint,
    enableLogging: config.isDev,
  });

  if (success) {
    const nuxtApp = useNuxtApp();
    nuxtApp.provide('braze', braze);
    console.log('Braze: Initialized successfully');
  }
  else {
    console.log('Braze: Unable to initialize Braze');
  }

  return success;
};

const startBraze = async () => {
  if (!isBrazeLoaded()) {
    await initializeBraze();
  }

  const { $braze } = useNuxtApp();

  if (!$braze) {
    return;
  }

  const userStore = useUserStore();

  try {
    if (!userStore.isGuest) {
      // If the user is not a guest then change the braze user, but only do this once, since
      // calling changeUser() multiple times is discouraged.
      const changedBrazeUser = 'changedBrazeUser';
      const hasChangedBrazeUser = sessionStorageService.getItem(changedBrazeUser);

      if (!hasChangedBrazeUser) {
        console.log(`Braze: changing user to ${userStore.user.uuid}`);
        $braze.changeUser(userStore.user.uuid);
        sessionStorageService.setItem(changedBrazeUser, true);
      }
    }

    // Automatically show in app messages _after_ changing user
    $braze.automaticallyShowInAppMessages();

    console.log('Braze: opening session');
    $braze.openSession();
  }
  catch (e) {
    // Do nothing, just try again later
  }
};

// ----------------------------------------------------------------------------------------------
// Gtag Tracking
// ----------------------------------------------------------------------------------------------

const initializeGtag = () => {
  const { gtagId } = useRuntimeConfig().public;

  if (!gtagId) {
    console.log('Gtag: Not loaded, missing gtagId!');
    return false;
  }

  const { enableAnalytics, initialize } = useGtag();
  enableAnalytics(gtagId);
  initialize(gtagId);

  console.log('Gtag: Initialized successfully');

  return true;
};

// ----------------------------------------------------------------------------------------------
// The Trade Desk (AdCellerant) Tracking
// ----------------------------------------------------------------------------------------------

const startTtdTracking = () => {
  const { isDev, isVitest } = useRuntimeConfig().public;

  if (isVitest) {
    console.log('TTD: Not loaded, in Vitest!');
    return;
  }

  if (isDev) {
    console.log('TTD: Not loaded, in development');
    return;
  }

  try {
    window.ttd_dom_ready(function handleTtdDomReady() {
      if (typeof window.TTDUniversalPixelApi === 'function') {
        const universalPixelApi = new window.TTDUniversalPixelApi();
        universalPixelApi.init('eac6kka', ['6g2ban0'], 'https://insight.adsrvr.org/track/up');
        console.log('TTD: Initialized');
      }
    });
  }
  catch (e) {
    console.log('TTD: Error initializing universal pixel API!');
    console.dir(e);
  }
};

// ----------------------------------------------------------------------------------------------
// General Purpose Methods
// ----------------------------------------------------------------------------------------------

const canTrackUser = () => {
  const { inAppView } = useInAppView();

  if (inAppView.value === true) {
    console.log('Not tracking user, in app view');
    return false;
  }

  const userStore = useUserStore();

  if (userStore.user?.privacy?.data_collection === false) {
    console.log('Not tracking user, disabled by user');
    return false;
  }

  if (userStore.user?.privacy?.data_collection === true) {
    return true;
  }

  const cookieNoticeAcceptedCookie = useCookieWrapper('cookienotice-accepted');

  if (cookieNoticeAcceptedCookie.value === false) {
    console.log('Not tracking user, disabled by guest user');
    return false;
  }

  if (cookieNoticeAcceptedCookie.value === true) {
    return true;
  }

  console.log('Not tracking user, consent not granted');
  return false;
};

const disableTracking = () => {
  const { gtagId } = useRuntimeConfig().public;
  const { disableAnalytics } = useGtag();
  disableAnalytics(gtagId);

  const { $braze } = useNuxtApp();

  if ($braze) {
    $braze.disableSDK();
  }

  const iframe = document.querySelector('iframe[title="TTD Universal Pixel"]');
  iframe?.remove();
};

const startTracking = async () => {
  let trackingStarted = false;

  try {
    if (canTrackUser()) {
      await startBraze();
      initializeGtag();
      startTtdTracking();
      trackingStarted = true;
    }
  }
  catch (e) {
    Sentry.captureException(e);
    console.warn('UserTrackingService.startTracking(): Unable to start tracking', e);
    trackingStarted = false;
  }

  return trackingStarted;
};

export default {
  canTrackUser,
  disableTracking,
  initializeGtag,
  startTracking,
};
