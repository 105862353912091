<script setup>
import { usePreferredColorScheme } from '@vueuse/core';
import debounce from 'lodash.debounce';
import { useMapStore } from '@@/stores/Map';
import { useUserStore } from '@@/stores/User';
import { watch } from 'vue';

const mapStore = useMapStore();
const userStore = useUserStore();

const { inAppView } = useInAppView();
const style = ref({ '--vh': '1vh' });
const preferredColorScheme = usePreferredColorScheme();

const route = useRoute();

const showFooter = computed(() => {
  if (mapStore.ui.isFullScreen && route.path.startsWith('/map')) {
    return false;
  }

  if (inAppView.value) {
    return false;
  }

  return true;
});

/**
 * Don't display the "apple-itunes-app" meta tag when viewing a page in the mobile app so the
 * Smart App Banner is not displayed.
 * @see https://nuxt.com/docs/getting-started/seo-meta
 * @see https://developer.apple.com/documentation/webkit/promoting_apps_with_smart_app_banners
 */
useHead({
  meta: inAppView.value
    ? []
    : [{ name: 'apple-itunes-app', content: 'app-id=780890907' }],
});

const setColorTheme = () => {
  let newTheme;

  if (userStore.preferences.theme === 'system') {
    newTheme = preferredColorScheme.value === 'dark' ? 'dark' : 'light';
    userStore.computedTheme = newTheme;
  }
  else {
    newTheme = userStore.preferences.theme;
  }

  document.documentElement.setAttribute('data-theme', newTheme);
};

/**
 * Set the view height variable on the <main> element so that full screen pages can be sized
 * correctly when a mobile browser address bar and OS navigation elements are displayed.
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
const setViewHeight = () => {
  const vh = window.innerHeight / 100;
  style.value = { '--vh': `${vh}px` };
};

let handleResize;

/**
 * Reset the view height variable after the window has resized.
 */
const addResizeHandler = () => {
  handleResize = debounce(() => setViewHeight(), 500);
  window.addEventListener('resize', handleResize);
};

onMounted(() => {
  // Get the theme from the URL or local storage, and then set it in the user.preferences. Then
  // set the data-theme attribute on the document.
  const newTheme = userStore.getTheme();
  userStore.setTheme(newTheme);
  setColorTheme();

  userStore.getUnits();

  setViewHeight();
  addResizeHandler();

  // On mobile browsers, when a full screen page is reloaded, the browser's bottom navigation
  // menu is already shown, so it doesn't disappear and/or reappear, and a resize event isn't
  // triggered. As a result the view height isn't set properly and when on the map page, the
  // control bar is displayed below the fold. Calling the setViewHeight() method 2.5s after the
  // page loads is a hack to resolve this issue!

  window.setTimeout(setViewHeight, 2500);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

watch(() => userStore.preferences.theme, setColorTheme);
watch(preferredColorScheme, setColorTheme);
</script>

<template>
  <main
    id="root"
    :class="$style.container"
    :style="style"
  >
    <SiteHeader
      v-if="!inAppView"
      :class="$style.siteHeader"
    />
    <div :class="$style.siteContent">
      <slot />
    </div>
    <SiteFooter
      v-if="showFooter"
      :class="$style.siteFooter"
    />
    <client-only>
      <CookieNotice
        v-if="!inAppView"
      />
      <div
        id="mapContainer"
        class="tw-h-full tw-w-full tw-hidden"
      />
      <!--
        <BlackFridaySaleDialog />
      -->
    </client-only>
  </main>
</template>

<style module>
.container {
  display: grid;
  font-size: 1rem;
  grid-template:
    "siteHeader" auto
    "siteContent" minmax(60vh, 1fr)
    "siteFooter" auto;
  line-height: 1.5rem;
  min-height: calc(100 * var(--vh, 1vh));
}

.siteHeader {
  grid-area: siteHeader;
}

.siteContent {
  grid-area: siteContent;
  width: 100vw;
}

@media (min-width: 768px) {
  .siteContent {
    width: auto;
  }
}

.siteFooter {
  grid-area: siteFooter;
}
</style>
