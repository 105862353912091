<script setup>
import { getBadgeProps } from '@@/components/Common/Badges/BadgeProps';

const props = defineProps({
  ...getBadgeProps([
    'backgroundColor',
    'borderColor',
    'color',
    'paddingX',
    'paddingY',
    'shape',
    'size',
  ]),

  icon: {
    type: String,
    default: '',
  },
});

const allAdditionalClassNames = computed(() => {
  const classNames = ['tw-font-medium'];

  if (typeof props.additionalClassNames === 'string') {
    classNames.push(props.additionalClassNames);
  }
  else if (Array.isArray(props.additionalClassNames)) {
    classNames.push(...props.additionalClassNames);
  }

  return classNames;
});
</script>

<template>
  <Badge
    :additional-class-names="allAdditionalClassNames"
    background-color="all-access-background-color"
    color="tw-text-white"
    shape="rounded"
    size="xs"
  >
    <template #default>
      <font-awesome-icon
        v-if="icon"
        class="tw-mr-1"
        :icon="icon"
      />
      <span class="tw-uppercase">
        <slot>All-Access</slot>
      </span>
    </template>
  </Badge>
</template>
