<script setup>
import AppStoreDark from '~/assets/svg/AppStoreDark.svg';
import AppStoreLight from '~/assets/svg/AppStoreLight.svg';

const props = defineProps({
  alwaysUseAppStoreDarkButton: {
    type: Boolean,
    default: false,
  },
  containerClass: {
    type: String,
    default: 'tw-my-6 tw-text-center',
  },
});

const appStoreUrl = 'https://apps.apple.com/us/app/opensnow-ski-forecasts-reports/id780890907?ls=1';
const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.opensnow.android';

const $style = useCssModule();

const appStoreDarkClass = computed(() => props.alwaysUseAppStoreDarkButton
  ? 'tw-inline-block tw-mr-1'
  : ['tw-mr-1', $style.appStoreLightModeButton]);

const appStoreLightClass = computed(() => props.alwaysUseAppStoreDarkButton
  ? 'tw-hidden'
  : ['tw-mr-1', $style.appStoreDarkModeButton]);
</script>

<template>
  <div :class="containerClass">
    <a
      class="hover:tw-no-underline"
      :href="appStoreUrl"
      target="_blank"
    >
      <AppStoreDark
        :class="appStoreDarkClass"
      />
      <AppStoreLight
        :class="appStoreLightClass"
      />
    </a>
    <a
      :href="playStoreUrl"
      target="_blank"
    >
      <img
        alt="Get it on Google Play"
        class="tw-inline-block tw-h-10 tw-ml-1"
        src="~/assets/img/GetItOnGooglePlay.png"
      >
    </a>
  </div>
</template>

<style module>
.appStoreLightModeButton {
  display: inline-block;
}

.appStoreDarkModeButton {
  display: none;
}

:global([data-theme="dark"]) .appStoreLightModeButton {
  display: none;
}

:global([data-theme="dark"]) .appStoreDarkModeButton {
  display: inline-block;
}
</style>
