<script setup>
defineProps({
  error: Object,
});
</script>

<template>
  <Main>
    <template #default>
      <div class="container page-content tw-overflow-hidden">
        <ErrorPage :error="error" />
      </div>
      <ClientOnly>
        <ErrorLogging :error="error" />
      </ClientOnly>
    </template>
  </Main>
</template>
