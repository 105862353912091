import { useMetaStore } from '@@/stores/Meta';

export const defaultTrialText = 'Create Free Account';

export const useDefaultTrial = () => {
  const metaStore = useMetaStore();

  const defaultTrial = computed(() => metaStore.pricing?.default_trial);
  const defaultTrialTitle = computed(() => defaultTrial.value?.trial_title || defaultTrialText);
  const registerButtonText = computed(() => defaultTrial.value?.trial_button_text || defaultTrialText);

  return {
    defaultTrial,
    defaultTrialTitle,
    registerButtonText,
  };
};
