<script setup>
import { useCssModule } from 'vue';
import { getBadgeProps } from '@@/components/Common/Badges/BadgeProps';

const props = defineProps(getBadgeProps());

const $style = useCssModule();

const containerClass = computed(() => {
  let sizeClass;

  if (props.size === 'base') {
    sizeClass = 'tw-text-xs';
  }
  else if (props.size === 'sm') {
    sizeClass = $style.sm;
  }
  else { // props.size === 'xs'
    sizeClass = $style.xs;
  }

  const classNames = [
    'tw-inline-block',
    props.borderColor ? 'tw-border' : null,
    props.shape === 'rounded' ? 'tw-rounded-full' : 'tw-rounded',
    'tw-font-bold',
    props.paddingX,
    props.paddingY,
    sizeClass,
    props.backgroundColor,
    props.borderColor,
    props.color,
    $style.container,
  ];

  if (props.additionalClassNames && typeof props.additionalClassNames) {
    classNames.push(props.additionalClassNames);
  }
  else if (Array.isArray(props.additionalClassNames)) {
    classNames.push(...props.additionalClassNames);
  }

  return classNames;
});
</script>

<template>
  <small :class="containerClass">
    <slot />
  </small>
</template>

<style module>
.container {
  height: fit-content;
}

.sm {
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.xs {
  font-size: 0.625rem;
  line-height: 0.75rem;
}
</style>
