import ChartAnnotation from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';

/**
 * This is a simple Nuxt plugin to register the controllers, elements, scales and plugins that we
 * will use. It will inject the $chart property to all Vue components.
 */
import {
  Chart,
  // ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  // BubbleController,
  // DoughnutController,
  LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  // TimeScale,
  // TimeSeriesScale,
  // Decimation,
  // Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from 'chart.js';

import * as helpers from 'chart.js/helpers';

export default defineNuxtPlugin(() => {
  Chart.defaults.animation = false;
  Chart.defaults.font.family = "'Clear Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

  Chart.register(
    // ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    // BubbleController,
    ChartAnnotation,
    ChartDataLabels,
    // DoughnutController,
    LineController,
    // PieController,
    // PolarAreaController,
    // RadarController,
    // ScatterController,
    CategoryScale,
    LinearScale,
    // LogarithmicScale,
    // RadialLinearScale,
    // TimeScale,
    // TimeSeriesScale,
    // Decimation,
    // Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
  );

  Chart.helpers = helpers;

  return {
    provide: {
      chart: Chart,
    },
  };
});
