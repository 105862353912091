<script setup>
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const config = useRuntimeConfig().public;

// The <ErrorLogging> component is rendered on the client side only, so the window object will be
// available. And since it appears that the onMounted() life cycle hook is not called in the error
// page, maybe because it does not specify a layout, or maybe because it's not really a "page";
// rendering a client only component and just logging the error when rendered seems like a fine
// workaround.
window.console.error(`${config.siteName} Error: message = ${props.error.message}, statusCode = ${props.error.statusCode}`);
window.console.error(props.error);
</script>

<template>
  <div />
</template>
