const removeProps = (props, propsToRemove) => {
  propsToRemove.forEach((prop) => {
    delete props[prop];
  });
};

export const getBadgeProps = (propsToRemove = []) => {
  const props = {
    additionalClassNames: {
      type: [Array, String],
      default: null,
    },
    backgroundColor: {
      type: String,
      default: 'view-background-color',
    },
    borderColor: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'text-darkest-color',
    },
    paddingX: {
      type: String,
      default: 'tw-px-2',
    },
    paddingY: {
      type: String,
      default: 'tw-py-1',
    },
    shape: {
      type: String,
      default: 'rounded',
      validator(value) {
        return ['rounded', 'square'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'base',
      validator(value) {
        // TODO: Add 'lg' if/when needed.
        return ['xs', 'sm', 'base'].includes(value);
      },
    },
  };

  removeProps(props, propsToRemove);

  return props;
};

export const getMapBadgeProps = (propsToRemove = []) => {
  const props = {
    ...getBadgeProps(),
    paddingX: {
      type: String,
      default: 'tw-px-1.5',
    },
    paddingY: {
      type: String,
      default: 'tw-py-0.5',
    },
    shape: {
      type: String,
      default: 'square',
    },
    size: {
      type: String,
      default: 'base',
    },
  };

  removeProps(props, propsToRemove);

  return props;
};
