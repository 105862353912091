import { navigateTo } from 'nuxt/app';

/**
 * Redirect from old paths and legacy pages to new pages. Note that redirects are handled here, in
 * middleware, rather than in the routeRules because Sentry is flooded with failed app manifest
 * requests. The app manifest is used to ensure client side routes can redirect properly when
 * specified in routeRules in nuxt.config.ts. It is hoped that by performing redirects here, for
 * both the client and server side routing, that these Sentry errors will stop.
 * @see
 * - https://cloudnineweather.sentry.io/issues/5635527733/
 * - https://nuxt.com/blog/v3-8#app-manifest
 * - https://masteringnuxt.com/blog/how-to-redirect-in-nuxt-every-single-way
 */
export default defineNuxtRouteMiddleware((to) => {
  const pathStartsWith = (string) => String(to?.path).startsWith(string);
  const match10DayForecast = to?.fullPath.match(/\/explore\/(.+)\/(.+)\/10-day-forecast/);
  const match10DayHistory = to?.fullPath.match(/\/explore\/(.+)\/(.+)\/10-day-history/);
  const matchSummary = to?.fullPath.match(/\/explore\/(.+)\/(.+)\/summary/);

  let hash;
  const options = { redirectCode: 308 };
  let path;

  if (match10DayForecast) {
    path = `/explore/${match10DayForecast[1]}/${match10DayForecast[2]}/snow-forecast`;
  }
  else if (match10DayHistory) {
    path = `/explore/${match10DayHistory[1]}/${match10DayHistory[2]}/snow-history`;
  }
  else if (matchSummary) {
    path = `/explore/${matchSummary[1]}/${matchSummary[2]}/snow-summary`;
  }
  else if (pathStartsWith('/user/favorites/locations/summary')) {
    path = '/user/favorites/locations/snow-summary';
  }
  else if (pathStartsWith('/user/favorites/locations/10-day-forecast')) {
    path = '/user/favorites/locations/snow-forecast';
  }
  else if (pathStartsWith('/user/favorites/locations/10-day-history')) {
    path = '/user/favorites/locations/snow-history';
  }
  else if (pathStartsWith('/user/notifications/newsletters')) {
    hash = '#email-newsletters';
    path = '/user/settings/notifications';
  }
  else if (pathStartsWith('/user/settings/alerts/dailysnow')) {
    hash = '#expert-local-forecasters';
    path = '/user/settings/notifications';
  }
  else if (pathStartsWith('/user/settings/alerts/snow')) {
    hash = '#snow-forecasts-and-reports';
    path = '/user/settings/notifications';
  }
  else if (pathStartsWith('/user/settings/alerts')) {
    path = '/user/settings/notifications';
  }
  else if (pathStartsWith('/user/settings/privacy')) {
    hash = '#privacy';
    path = '/user/settings/preferences';
  }
  // legal pages moved to support, Mar 11 2025
  else if (pathStartsWith('/terms')) {
    options.external = true;
    path = 'https://support.opensnow.com/legal/terms';
  }
  else if (pathStartsWith('/privacy')) {
    options.external = true;
    path = 'https://support.opensnow.com/legal/privacy';
  }
  else if (pathStartsWith('/partnerships')) {
    options.external = true;
    path = 'https://support.opensnow.com/legal/partnerships';
  }
  else if (pathStartsWith('/contact')) {
    options.external = true;
    path = 'https://support.opensnow.com/contact-us/contact';
  }
  else if (pathStartsWith('/careers')) {
    options.external = true;
    path = 'https://support.opensnow.com/legal/careers';
  }

  if (path) {
    return navigateTo({ hash, path, query: to.query }, options);
  }
});
