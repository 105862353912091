<script setup>
import * as Sentry from '@sentry/vue';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { useInAppView } from '@@/composables/useInAppView';

const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const { fullPath } = useRoute();
const config = useRuntimeConfig().public;

const isInStory = computed(() => fullPath.startsWith('/stories'));
const is404 = computed(() => props.error && props.error.statusCode === 404);
const title = computed(() => `${is404.value ? 'Page Not Found' : 'Something Broke'} | ${config.siteName}`);
const details = computed(() => is404.value
  ? "Sorry, we couldn't find that page."
  : 'We are having an issue loading this page. Try again later!');

const { inAppView } = useInAppView();
const showButton = inAppView.value ? false : true;

if (!isInStory.value) {
  useSeoMeta({ title });
  Sentry.captureException(props.error);
}

const handleGoBack = () => {
  clearError();

  if (window.history?.state?.back) {
    window.location.href = window.history.state.back;
  }
  else {
    window.history.go(-1);
  }
};
</script>

<template>
  <div
    :class="[
      'tw-flex tw-flex-col',
      'tw-my-6 tw-mx-auto',
      'tw-max-w-4xl',
    ]"
  >
    <h3 class="page-title heading-color tw-mb-4 tw-text-center">
      Yard Sale!
    </h3>

    <div
      :class="[
        'tw-relative',
        'tw-h-48 md:tw-h-64 lg:tw-h-80',
        'tw-aspect-4/3',
        'tw-rounded-md tw-overflow-hidden',
      ]"
    >
      <img
        alt="Yard sale on the slops"
        class="tw-w-full tw-h-full tw-object-cover tw-cursor-pointer"
        loading="lazy"
        src="~/assets/img/OpenSnowError.jpg"
      >
      <div
        :class="[
          'tw-absolute tw-bottom-0',
          'tw-flex tw-items-center tw-justify-center',
          'tw-w-full tw-h-1/5 tw-px-2 lg:tw-px-4',
          'tw-text-white background-color-saturated-blue',
        ]"
      >
        <span class="tw-text-sm lg:tw-text-base tw-text-center tw-font-medium">
          {{ details }}
        </span>
      </div>
    </div>

    <Button
      v-if="showButton"
      additional-class-names="tw-w-full"
      class="tw-mt-4 tw-text-lg"
      :display-block="true"
      :type="ButtonTypes.secondaryCard"
      @click="handleGoBack"
    >
      Go Back
    </Button>
  </div>
</template>
