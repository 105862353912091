<script setup>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { isScreenLg } from '@@/utils/CommonUtils';
import { addReturnTo, getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { useDefaultTrial } from '@@/composables/useDefaultTrial';
import { useLoginStore } from '@@/stores/Login';
import { useUserStore } from '@@/stores/User';
import { useUiStore } from '@@/stores/Ui';
import SiteHeaderSearch from './SiteHeaderSearch.vue';

/**
 * The site header has slightly different displays for different user types:
 *
 * - Guest User:
 *   - Mobile and Desktop: Help Link no Icon, Log In Link no Icon, Create Free Account Button
 * - Registered Free User:
 *   - Mobile: Help Link with Icon, Settings Link, Logout Link, Buy Now Button
 *   - Desktop: Help Link with Icon, Account Link with Icon, Buy Now Button, with Settings and
 *     Logout Links displayed in a dropdown menu
 * - Registered All-Access User:
 *   - Mobile: Help Link with Icon, Settings Link, Logout Link
 *   - Desktop: Help Link with Icon, Account Link with Icon, with Settings and Logout Links
 *     displayed in a dropdown menu
 */

const $style = useCssModule();

const config = useRuntimeConfig().public;
const route = useRoute();

const loginStore = useLoginStore();
const userStore = useUserStore();
const uiStore = useUiStore();

const { registerButtonText } = useDefaultTrial();
const isAllAccess = computed(() => userStore.isAllAccess);
const isGuest = computed(() => userStore.isGuest);

const showAccountDropdown = ref(false);

const accountDropdownPosition = computed(() => {
  if (!isAllAccess.value && !isGuest.value) {
    return $style.accountDropdownFreeUser;
  }

  return 'right';
});

const allAccessBadgeClassNames = computed(() => ([
  isAllAccess.value
    ? `tw-hidden lg:tw-inline-block ${$style.showAllAccess}`
    : 'tw-hidden',
  'tw-align-middle tw-whitespace-nowrap tw-overflow-x-hidden',
  $style.allAccessBadge,
]));

const canShowNavContent = computed(() => !isGuest.value);

const getReturnToQuery = () => {
  /* eslint camelcase: off */
  const return_to = route?.query?.return_to || route?.fullPath;

  if (typeof return_to !== 'string') {
    return {};
  }

  if (return_to.includes('/user/login')
    || return_to.includes('/user/register')
    || return_to === '/') {
    return {};
  }

  return { return_to };
};

const toggleAccountDropdown = (event) => {
  // Ignore the click event if a <DropdownMenu> was closed in its click outside handler. If the
  // click were handled here then the <DropdownMenu> would be _reopened_ so that a second click
  // wouldn't close it!
  if (event?.dropdownMenuClosed) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();
  showAccountDropdown.value = !showAccountDropdown.value;
};

const handleLogout = async (event) => {
  event.preventDefault();
  event.stopPropagation();
  await loginStore.makeLogoutRequest();
  window.location.href = '/user/logout';
};

const accountLink = {
  icon: 'user',
  onClick: toggleAccountDropdown,
  text: 'Account',
};

const logOutLink = {
  external: true,
  icon: 'arrow-right-from-bracket',
  onClick: handleLogout,
  text: 'Log Out',
  to: '/user/logout',
};

const settingsLink = {
  icon: 'cog',
  text: 'Settings',
  to: '/user/settings/account',
};

const helpLink = computed(() => ({
  external: true,
  icon: userStore.isGuest ? null : 'question',
  target: '_blank',
  text: 'Help',
  to: 'https://support.opensnow.com',
}));

const logInLink = computed(() => {
  const query = getReturnToQuery();
  const path = '/user/login';
  const text = 'Log In';
  const to = addReturnTo(path, query);

  return { text, to };
});

const navItemAnchorClass = 'tw-block lg:tw-inline-block tw-py-2 lg:tw-px-2 tw-cursor-pointer hover:tw-no-underline hover:tw-text-white';

const navItemClass = computed(() => ([
  isGuest.value ? 'lg:tw-block lg:tw-py-2' : '',
  'tw-text-base tw-font-normal',
  isGuest.value ? 'lg:tw-text-sm' : 'lg:tw-text-xs',
  'text-light-color tw-whitespace-nowrap',
]));

const navItemIconClass = 'tw-inline-block lg:tw-block tw-w-6 tw-h-4 tw-mr-1.5 lg:tw-mb-1 lg:tw-mx-auto tw-fill-current';

const dropdownLinks = [
  settingsLink,
  logOutLink,
];

const pageLinks = [
  {
    icon: ['far', 'location-dot'],
    text: 'My Location',
    to: '/my-location',
  },
  {
    icon: ['far', 'star'],
    text: 'Favorites',
    to: '/user/favorites',
  },
  {
    icon: ['far', 'map'],
    text: 'Maps',
    to: '/map',
  },
  {
    icon: ['fal', 'users'],
    text: 'Experts',
    to: '/dailysnow',
  },
  {
    icon: ['fas', 'search'],
    text: 'Explore',
    to: '/explore',
  },
];

const startFreeTrialButton = computed(() => {
  const query = getReturnToQuery();

  const params = {
    isGuest: isGuest.value,
    query: {
      ...query,
      source: 'snow_header',
    },
  };

  const buttonClass = [
    'tw-text-base lg:tw-text-sm',
    isGuest.value ? '' : 'lg:tw-my-2',
    'tw-w-full',
    'tw-whitespace-nowrap',
  ].join(' ');

  const text = isGuest.value ? registerButtonText.value : 'Buy Now';
  const to = getStartFreeTrialUrl(params);
  const type = ButtonTypes.allAccess;

  return {
    buttonClass,
    text,
    to,
    type,
  };
});

const userLinkSets = computed(() => {
  if (isGuest.value) {
    return [
      // Mobile
      [
        helpLink.value,
        logInLink.value,
        startFreeTrialButton.value,
      ],
      // Desktop
      [
        helpLink.value,
        logInLink.value,
        startFreeTrialButton.value,
      ],
    ];
  }

  if (!isAllAccess.value) {
    return [
      // Mobile
      [
        helpLink.value,
        settingsLink,
        logOutLink,
        startFreeTrialButton.value,
      ],
      // Desktop
      [
        helpLink.value,
        accountLink,
        startFreeTrialButton.value,
      ],
    ];
  }

  return [
    // Mobile
    [
      helpLink.value,
      settingsLink,
      logOutLink,
    ],
    // Desktop
    [
      helpLink.value,
      accountLink,
    ],
  ];
});

const userLinksClass = `lg:tw-order-3 tw-mt-1 lg:tw-mt-0 tw-pt-1 lg:tw-pt-0 tw-border-t lg:tw-border-t-0 ${$style.userLinks}`;

const getNavItemAnchorActiveClass = (pageLink) => {
  const path = String(pageLink.to);
  return route.path.startsWith(path) ? 'tw-text-white' : null;
};

const handleAccountDropdownClose = () => {
  showAccountDropdown.value = false;
};

const handleCollapseNav = () => {
  if (isScreenLg()) {
    // Don't collapse the navbar on large screens because it's not open!
    return;
  }

  window.setTimeout(() => {
    uiStore.setShouldCollapseNav(true);
  }, 250);
};

const handleDropdownLinkClick = (event, dropdownLink) => {
  showAccountDropdown.value = false;

  if (dropdownLink.onClick) {
    dropdownLink.onClick(event);
  }
};

const handleUserLinkClick = (event, userLink) => {
  if (userLink.onClick) {
    userLink.onClick(event);
  }
  else {
    handleCollapseNav();
  }
};

/**
 * Always try to collapse the Site Nav Bar when the user navigates to a different page so it
 * isn't awkwardly left open when a new page is displayed.
 */
watch(() => route.path, (to, from) => {
  if (to?.path !== from?.path) {
    handleCollapseNav();
  }
});
</script>

<template>
  <SiteNavBar
    :class="['lg:tw-sticky lg:tw-top-0 tw-z-30', $style.container]"
  >
    <template #brand>
      <NuxtLink to="/">
        <img
          :alt="config.siteName"
          class="tw-w-48 tw-inline"
          src="~/assets/img/OpenSnowLogo.png"
        >
      </NuxtLink>
      <AllAccessBadge :additional-class-names="allAccessBadgeClassNames" />
    </template>
    <template #menu>
      <div
        v-if="canShowNavContent"
        class="tw-relative tw-flex-auto tw-my-4 tw-mx-0 lg:tw-my-0 lg:tw-mr-4 lg:tw-order-2"
      >
        <SiteHeaderSearch />
      </div>
      <ul
        v-if="canShowNavContent"
        class="lg:tw-flex"
      >
        <li
          v-for="pageLink in pageLinks"
          :key="pageLink.text"
          :class="[navItemClass, 'lg:tw-mr-4']"
        >
          <NuxtLink
            :external="pageLink.external"
            :class="[navItemAnchorClass, getNavItemAnchorActiveClass(pageLink)]"
            :to="pageLink.to"
            @click="handleCollapseNav"
          >
            <font-awesome-icon
              v-if="pageLink.icon"
              :class="navItemIconClass"
              :icon="pageLink.icon"
            />
            {{ pageLink.text }}
          </NuxtLink>
        </li>
      </ul>
      <ul
        v-for="(userLinks, index) in userLinkSets"
        :key="index"
        :class="[
          userLinksClass,
          index === 0 ? 'lg:tw-hidden' : '',
          index === 1 ? 'tw-hidden lg:tw-flex' : '',
        ]"
      >
        <li
          v-for="userLink in userLinks"
          :key="userLink.text"
          :class="[
            navItemClass,
            'lg:tw-ml-4',
          ]"
        >
          <Button
            v-if="userLink.buttonClass"
            :additional-class-names="userLink.buttonClass"
            :display-block="true"
            :to="userLink.to"
            :type="userLink.type"
          >
            {{ userLink.text }}
          </Button>
          <NuxtLink
            v-else
            :class="[navItemAnchorClass, getNavItemAnchorActiveClass(userLink)]"
            :external="userLink.external"
            :target="userLink.target"
            :to="userLink.to"
            @click="(event) => handleUserLinkClick(event, userLink)"
          >
            <font-awesome-icon
              v-if="userLink.icon"
              :class="navItemIconClass"
              :icon="userLink.icon"
            />
            {{ userLink.text }}
          </NuxtLink>
        </li>
      </ul>
      <DropdownMenu
        additional-dropdown-class="tw-order-4 tw-top-8"
        :card-props="{
          cardBackgroundColor: '',
          cardClassNames: 'brand-color',
          hasBodyPaddingSmall: true,
        }"
        :position="accountDropdownPosition"
        :show-menu="showAccountDropdown"
        style="/* stylelint-disable */ --dropdown-menu-show-menu-min-height: 2rem;"
        @close="handleAccountDropdownClose"
      >
        <template #menu>
          <ul>
            <li
              v-for="dropdownLink in dropdownLinks"
              :key="dropdownLink.text"
              :class="[
                'tw-w-64 first:tw-border-b first:tw-pb-1',
                'text-light-color hover:tw-text-white',
                $style.accountDropdownListItem,
              ]"
            >
              <NuxtLink
                class="tw-block tw-py-2 tw-cursor-pointer hover:tw-no-underline"
                :external="dropdownLink.external"
                :to="dropdownLink.to"
                @click="(event) => handleDropdownLinkClick(event, dropdownLink)"
              >
                <font-awesome-icon
                  v-if="dropdownLink.icon"
                  class="tw-inline-block tw-w-6 tw-h-4 tw-mr-1.5 tw-fill-current"
                  :icon="dropdownLink.icon"
                />
                {{ dropdownLink.text }}
              </NuxtLink>
            </li>
          </ul>
        </template>
      </DropdownMenu>
    </template>
  </SiteNavBar>
</template>

<style module>
.allAccessBadge {
  margin-left: -1.3rem;
  max-width: 0;
  opacity: 0;
  transition:
    all
    var(--auth-content-transition-duration)
    var(--auth-content-transition-function)
    var(--auth-content-transition-duration);
}

/*
 * TODO: Currently hiding the all access badge on mobile like the current site. This could probably
 * be implemented better in the future so that the hamburger doesn't wrap.
 */
@media (min-width: 992px) {
  .allAccessBadge.showAllAccess {
    margin-left: 0.35rem;
    max-width: 4.5rem;
    opacity: 1;
  }
}

.userLinks {
  border-color: var(--user-links-border-color);
}

@media (min-width: 992px) {
  .userLinks {
    transition:
      width,
      opacity
      var(--auth-content-transition-duration)
      var(--auth-content-transition-function);
  }

  .userLinks.hideAuthContent {
    opacity: 0;
    width: 13.125rem;
  }
}

.accountDropdownFreeUser {
  left: -26rem;
}

.accountDropdownListItem {
  border-color: var(--user-links-border-color);
}

.container :global(.nuxt-link-active) {
  color: white;
}
</style>
